import "./Loading.scss";
import React from "react";

function Loading() {
  return (
    <>
      <h1 className="loading">Loading...</h1>
    </>
  );
}

export default Loading;
